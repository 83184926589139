import React from "react";
import axios from "axios";

function About() {

    const userLang = navigator.language.substring(0, 2);
    const [title, setTitle] = React.useState("About");
    const [text, setText] = React.useState("about text");
    const baseUrl = process.env.REACT_APP_API_URL + "/site/" + process.env.REACT_APP_SITE_NAME + "/" + userLang + "/about";

    React.useEffect(() => {
        axios.get(baseUrl).then((response) => {
            setTitle(response.data.title as string);
            setText(response.data.text as string);
        });
    }, []);

    if (!title) return null;

    // @ts-ignore
    return (
        <div>
            <div className="float-container">
                <div className="float-child-left">
                    <div className='home-content'>
                        <h2>{title}</h2>
                        <div dangerouslySetInnerHTML={{__html: text}}/>
                    </div>

                </div>
                <div className="float-child-right">
                    <div id="sidebar-wrapper">
                        <p className="sidebar-blogs">

                            <div id='linkContainer' className='ml-0'>
                                <img className='mb-5' src="https://content.christine.nl/pics/83A0488-300.jpg" width="300px"/>
                                <a href="https://www.bol.com/nl/nl/p/agile-zoals-het-bedoeld-is/9200000117890588/?suggestionType=searchhistory&bltgh=ioNdn8a08w-iRyV6GFdNvA.1.2.ProductTitle">
                                    <img className="mb-5 mt-5 ml-5" src="https://content.christine.nl/website-images/boek-cover.jpg"/></a>
                                <img className="mt-5" src="https://content.christine.nl/pics/moortjepoes600.jpg" width="300px" />
                            </div>
                         </p>
                    </div>
                </div>

            </div>
        </div>
    );
}


export default About
