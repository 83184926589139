import React, {Component} from "react";
import axios from "axios";
import language from "./language";
import {Link} from "react-router-dom";
import strings from "./strings";


class Home extends Component<any,  any> {

    state: { language: string; text: string; title: string; list: any[] };

    constructor(props: any) {
        super(props)

        const lang = language();
        const blogLang = 'any';
        const numberOfBlogItems = 5;
        const baseUrl = process.env.REACT_APP_API_URL + "/site/" + process.env.REACT_APP_SITE_NAME + "/" + lang + "/home";
        const blogUrl = process.env.REACT_APP_API_URL + "/blogs/" + process.env.REACT_APP_SITE_NAME + "/" + blogLang + "/" + numberOfBlogItems;

        this.state = {
            title: '',
            text: '',
            list: [],
            language: lang
        }
        this.post = this.post.bind(this)
        this.get_blog_list = this.get_blog_list.bind(this)

        this.post(baseUrl)
        this.get_blog_list(blogUrl);
    }

    post(url: string) {
        const headers = {
            "Content-Type": "application/json",
        };

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    this.setState({
                        title: response.data.title,
                        text: response.data.text,
                        id: response.data.id,
                        date: response.data.date_string
                    })
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    get_blog_list(url: string) {
        const headers = {
            "Content-Type": "application/json",
        };

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    this.setState({
                        list: response.data,
                    })
                    window.scrollTo(0, 0)
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    render() {

        const title = this.state.title;
        const text = this.state.text;

        const list = this.state.list
        let  blog_list = list.map((item) =>
                <div key={item.id}>
                    <div className='blog_list_item mt-3 ml-3 mr-3'><Link to={'/blog/' + item.id}>{item.title}</Link>
                        <div className='date_string'> {item.date_string.substring(0, 10)}</div>
                    </div>
                </div>
            );

        return (

            <div>
                <div className="float-container">
                    <div className="float-child-left">
                        <div className='home-content'>
                            <h2>  {title}  </h2>
                            <div dangerouslySetInnerHTML={{__html: text}}/>
                        </div>

                    </div>
                    <div className="float-child-right">
                        <div id="sidebar-wrapper">
                            <p className="sidebar-blogs">

                                <div id='linkContainer' className='ml-0'>

                                    <div className='mb-5'>
                                        <img src="https://content.christine.nl/pics/tek-Karman-300.jpg" width="300px"/>
                                        <a href="https://www.bol.com/nl/nl/p/agile-zoals-het-bedoeld-is/9200000117890588/?suggestionType=searchhistory&bltgh=ioNdn8a08w-iRyV6GFdNvA.1.2.ProductTitle">
                                            <img className="mt-5 ml-5"
                                                 src="https://content.christine.nl/website-images/boek-cover.jpg"/></a>
                                    </div>
                                    <hr></hr>
                                    <p className='mt-3 ml-5 mr-5'>{strings.blog}</p>
                                    <div>{blog_list}</div>
                                </div>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        )
            ;
    }
}


export default Home
