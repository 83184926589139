import React, {Component} from "react";
import axios from "axios";
import {Link, Navigate} from "react-router-dom";
import strings from "./strings";
import AuthenticationService from "./service/AuthenticationService";


class Blogstech extends Component<any, any> {

    state: { language: string; text: string; title: string; list: any[], show_blog: boolean, id: number, date: string, go_edit: boolean, go_create: boolean, show_all: boolean, blog_language: string }

    constructor(props: any) {
        super(props)

        this.state = {
            language: "",
            text: "",
            title: "",
            list: [],
            show_blog: false,
            id: 0,
            date: '',
            go_edit: false,
            go_create: false,
            show_all: false,
            blog_language: ''
        }

        strings.setLanguage(navigator.language.substring(0, 2))

        this.get = this.get.bind(this)

        const params = window.location.href.split('/')
        const category : string = params[4];
        if (category !== undefined) {
             this.postCat(`${process.env.REACT_APP_API_URL}/categories/`, category)
        } else {
            this.get(`${process.env.REACT_APP_API_URL}/all_blogs/${process.env.REACT_APP_SITE_NAME}/nl`);
        }


    }

    postCat(url: string, category: string) {

        const postData = {
            site: 'christine',
            language: 'any',
            categories: category === 'tech' ? ['Programming', 'Agile'] : (category === 'art' ? ['Art History'] : ['Vlekjes'] )
        }
        let axiosConfig = AuthenticationService.getAxiosConfig();

         axios.post(url, postData, axiosConfig)
            .then(response => {
                    this.setState({
                        list: response.data,
                    })
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    get(url: string) {
        const headers = {
            "Content-Type": "application/json",
        };

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    this.setState({
                        list: response.data,
                    })
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    render() {

        if (this.state.show_blog) {
            <Navigate to={'blog/' + 99}/>
        }


        const list = this.state.list
        let blog_list: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | JSX.Element[] | null | undefined = []

        if (list !== undefined) {
            blog_list = list.map((item) =>
                <div key={item.id}>
                    <div className='blog_list_item mt-3'>{item.date_string.substring(0, 10)}
                        <Link className='ml-3' to={'/blog/' + item.id}>{item.title}</Link>
                    </div>
                </div>
            );
        }

        return (

            <div className="float-container">
                <div className='float-child-left'>
                    <div className='home-content'>
                        <div>

                            <div className='mt-5'>
                                {blog_list}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="float-child-right">
                    <p className="sidebar-blogs">
                        <img src="https://content.christine.nl/pics/3poezen.jpg" width="300px"/>
                        <img src="https://content.christine.nl/pics/vlekjes.jpg" width="300px"/>
                        <img src="https://content.christine.nl/pics/pengo.jpg" width="300px"/>
                        <img src="https://content.christine.nl/pics/mitnick.jpg" width="300px"/>

                    </p>
                </div>
            </div>
        )

    }
}

export default Blogstech
