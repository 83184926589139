import React, {Component} from "react";
import axios from "axios";
import {Link, Navigate} from "react-router-dom";
import strings from "./strings";
import AuthenticationService from "./service/AuthenticationService";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import {categories} from "./categories";

class EditBlog extends Component {

    state: { language: string; text: string; title: string; list: any[], date: string, category: string, editDone: boolean, id: number, showpopup: boolean , categories: string[]};

    constructor(props: {} | Readonly<{}>) {
        super(props)

        this.state = {
            title: '',
            text: '',
            date: '',
            category: 'Blog',
            editDone: false,
            id: 0,
            showpopup: false,
            list: [],
            language: 'nl',
            categories: categories
        }

        strings.setLanguage(navigator.language.substring(0, 2))

        this.post = this.post.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.hideModal = this.hideModal.bind(this);

        const params = window.location.href.split('/')
        const id = params[4]
        if (id !== undefined) {
            this.post(`${process.env.REACT_APP_API_URL}/blog/${process.env.REACT_APP_SITE_NAME}/${navigator.language.substring(0, 2)}/${id}`);
        }
    }

    handleTextChange(event: { target: { value: any; }; }) {
        this.setState({text: event.target.value});
    }

    handleTitleChange(event: { target: { value: any; }; }) {
        this.setState({title: event.target.value});
    }

    handleCategoryChange(event: { target: { value: any; }; }) {
        this.setState({category: event.target.value});
    }

    handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();

        if (this.state.title.length < 2 && this.state.text.length < 3) {
            this.setState({
                showpopup: true
            })
        } else {

            let postData = {
                text: this.state.text,
                title: this.state.title,
                category: this.state.category,
                id: this.state.id,
                site: process.env.REACT_APP_SITE_NAME,
                language: 'nl'
            };

            axios.post(process.env.REACT_APP_API_URL + '/admin/edit_blog/' + process.env.REACT_APP_SITE_NAME + "/" + strings.getLanguage(),
                postData,
                AuthenticationService.getAxiosConfig()
            )
                .then(response =>
                    this.setState({
                        id: response.data.id,
                        text: response.data.text,
                        title: response.data.title,
                        category: response.data.category,
                        date: response.data.date_string,
                        editDone: true
                    })
                )
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        console.log(error.message);
                        console.log(error.stack);
                    }
                })
        }
    }

    post(url: string) {
        const headers = {
            "Content-Type": "application/json",
        };

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    this.setState({
                        title: response.data.title,
                        text: response.data.text,
                        id: response.data.id,
                        date: response.data.date_string,
                        category: response.data.category
                    })
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    handleSelect(event: { target: { value: string; }; }) {
        console.log(event.target.value)
        this.setState({
            category: event.target.value
        })
    }

    hideModal() {
        this.setState({
            showpopup: false
        })
    }

    setSelection() {
        let selectIndex = 0;

        console.log('categories ' + categories);
        console.log('category in state ' + this.state.category)

        for (let i = 0; i < categories.length; i++) {
            if (categories[i] === this.state.category) {
                selectIndex = i;
            }
        }
        if (document !== null && document.getElementById("categorySelect") !== null) { // @ts-ignore
            document.getElementById("categorySelect").selectedIndex = selectIndex;
        }

        console.log('selectIndex ' + selectIndex)
    }

    render() {

        if (this.state.editDone === true) {
            return <Navigate to={'/blog/' + this.state.id}></Navigate>
        }
        this.setSelection()

        const linkTo = '/blog/' + this.state.id

        // @ts-ignore
        return (
            <div className='container'>

                <Modal show={this.state.showpopup}>
                    <Modal.Header>
                        <Modal.Title>No input</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>You need to type something</Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.hideModal}>Cancel</button>
                    </Modal.Footer>
                </Modal>

                <div>
                    <div>
                        <div>
                            <h3> {this.state.date}</h3>
                        </div>
                        {/* https://www.jquery-az.com/bootstrap-select-5-beautiful-styles/ */}
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <div>
                                    <div className="col-sm-2">
                                        <select className="form-control inputstl" id="categorySelect"
                                                name="categorySelect"
                                                onChange={this.handleSelect}>
                                            <option value='Blog'>Blog
                                            </option>
                                            <option value='Programming'>Programming
                                            </option>
                                            <option value='Agile'>Agile
                                            </option>
                                            <option value='Art History'>Art History
                                            </option>
                                            <option value='Taalblog'>Taalblog
                                            </option>
                                            <option value='Vlekjes'>Vlekjes
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-5 mt-3">
                                    <textarea className="form-control inputstl extratitlearea"
                                              rows={1}
                                              placeholder="Title"
                                              id="title_string"
                                              value={this.state.title}
                                              onChange={this.handleTitleChange}
                                    />
                                </div>
                                <div className="mt-3">
                                 <textarea className="inputstl extratextarea"
                                           placeholder="Text"
                                           id="text_string"
                                           value={this.state.text}
                                           onChange={this.handleTextChange}
                                 /></div>
                            </div>
                            <table className='mt-5'>
                                <tr>
                                    <td>
                                        <input
                                            type="submit"
                                            className="btn btn-outline-success mybutton"
                                            value="Submit"
                                        />
                                    </td>
                                    <td>
                                        <Link className="btn btn-outline-danger mybutton" to={linkTo}>Cancel</Link>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditBlog
