import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import About from './About'
import Home from './Home'
import Blog from './Blog'
import Blogs from './Blogs'
import Login from './Login'
import EditBlog from './EditBlog'
import logo180 from './images/logo180.png'
import './App.css';
import './css/bootstrap.css'
import strings from './strings.js'
import Blogstech from "./Blogstech";
import Blogsart from "./Blogsart";
import Blogstaal from "./Blogstaal";
import SchwartzeAnsingh from "./SchwartzeAnsingh"

class App extends Component<any,any> {

    constructor(props:any | Readonly<{}>) {
        super(props);
        const userLang = navigator.language.substring(0, 2);
        strings.setLanguage(userLang);
    }

    render() {

        return (
            <Router>
                <div className='container content-header'>
                    <div className='jumbotron'>
                        <div className='header-bar header-bar-color'>
                            <table width="100%">
                                <tbody>
                                <tr>
                                    <td>
                                        <h1 className='header-title'>{strings.titel}</h1>
                                        <nav className='navbar navbar-expand-lg navbar-light header-text'>
                                            <p className="navbar-nav"><Link to="/">{strings.home}</Link></p>
                                            <p className="navbar-nav"><Link to="/blog">{strings.blog}</Link></p>
                                            <p className="navbar-nav"><Link to="/blogstech/tech">{strings.tech}</Link></p>
                                            <p className="navbar-nav"><Link to="/blogstaal/taalblog">{strings.taal}</Link></p>
                                            <p className="navbar-nav"><Link to="/blogsart/art">{strings.art}</Link></p>
                                            <p className="navbar-nav"><Link to="/schwartze/">{strings.schwartzeansingh}</Link></p>
                                            <p className="navbar-nav ml-5"><Link to="/about">{strings.about}</Link></p>
                                        </nav>
                                    </td>
                                    <td>
                                        <img src={logo180} className="logo" alt="logo"/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='container content'>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/about" element={<About/>}/>
                                <Route path="/blog/:id" element={<Blog/>}/>
                                <Route path="/blog" element={<Blog/>}/>
                                <Route path="/blogstech/:cat" element={<Blogstech/>}/>
                                <Route path="/blogsart/:cat" element={<Blogsart/>}/>
                                <Route path="/blogstaal/:cat" element={<Blogstaal/>}/>
                                <Route path="/all_blogs" element={<Blogs/>}/>
                                <Route path="/edit_blog/:id" element={<EditBlog/>}/>
                                <Route path="/edit_blog" element={<EditBlog/>}/>
                                <Route path="/schwartze" element={<SchwartzeAnsingh/>}/>
                                <Route path="/login" element={<Login/>}/>

                            </Routes>
                        </div>
                    </div>

                </div>
            </Router>
        )
    }
}

export default App;
